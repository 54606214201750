import React from 'react';
import Footer from './Footer';
import styles from './TermsAndConditions.module.css';
import NavBar from './Navbar';

const TermsAndConditions = () => {
  return (
    <>
      <NavBar />
      <div style={{direction:'rtl'}} className={styles.termsContainer}>
        <div className={styles.section}>
          <h1>תנאים והגבלות</h1>
          <p>ברוכים הבאים ל-Remember Wish. על ידי גישה ושימוש באתר ובשירותים שלנו, אתם מסכימים לציית ולהיות מחויבים על ידי התנאים וההגבלות הבאים. אנא קראו אותם בעיון. אם אינכם מסכימים עם כל חלק מהתנאים הללו, עליכם להימנע משימוש באתר או בשירותים שלנו.</p>
          
          <h2>1. הגדרות</h2>
          <p>
            <strong>שירות:</strong> פלטפורמת Remember Wish, כולל האתר, יישומים ניידים וכל שירותים נוספים שניתנים.<br />
            <strong>משתמש:</strong> כל אדם או גורם שניגש או משתמש בשירות.<br />
            <strong>תוכן:</strong> כל מידע, טקסט, תמונות, סרטונים וחומרים אחרים שמועלים, מפורסמים או זמינים בשירות.<br />
            <strong>קוד QR:</strong> הקוד הייחודי שמסופק על ידי Remember Wish המקשר לדפי זיכרון אישיים.
          </p>

          <h2>2. שימוש בשירות</h2>
          <p>
            <strong>זכאות:</strong> עליכם להיות בני 18 לפחות כדי להשתמש בשירות. על ידי שימוש בשירות, אתם מצהירים ומתחייבים כי אתם עומדים בדרישת הגיל הזו.<br />
            <strong>חשבון:</strong> ייתכן שתצטרכו ליצור חשבון כדי לגשת לתכונות מסוימות של השירות. אתם אחראים לשמור על סודיות המידע של החשבון שלכם ועל כל הפעולות שמתרחשות תחת החשבון שלכם.<br />
            <strong>תוכן משתמש:</strong> אתם נשארים הבעלים של כל התוכן שאתם מעלים או מפרסמים. על ידי העלאת תוכן, אתם מעניקים ל-Remember Wish רישיון לא בלעדי, חופשי מתמלוגים, עולמי להשתמש, להציג, לשכפל ולהפיץ את התוכן בקשר לשירות.
          </p>

          <h2>3. קודי QR</h2>
          <p>
            <strong>רכישה ושימוש:</strong> משתמשים יכולים לרכוש קודי QR דרך השירות. קודים אלה מיועדים לקישור לדפי זיכרון אישיים שנוצרו בפלטפורמה.<br />
            <strong>שימושים אסורים:</strong> אסור להשתמש בקודי QR לכל מטרה בלתי חוקית, לא מורשית או אסורה. Remember Wish שומרת לעצמה את הזכות להשבית קודי QR המשמשים בניגוד לתנאים הללו.
          </p>

          <h2>4. מדיניות פרטיות</h2>
          <p>
            <strong>איסוף נתונים:</strong> אנו אוספים מידע אישי שאתם מספקים בעת יצירת חשבון, רכישת שירותים או שימוש בשירות. מידע זה משמש כדי לספק ולשפר את השירות.<br />
            <strong>שימוש בנתונים:</strong> לא נשתף את המידע האישי שלכם עם צדדים שלישיים ללא הסכמתכם, אלא אם כן נדרש על פי חוק או נחוץ כדי לספק את השירות.<br />
            <strong>קוקיות:</strong> האתר עשוי להשתמש בקוקיות כדי לשפר את חוויית המשתמש. ניתן לשלוט בשימוש בקוקיות דרך הגדרות הדפדפן שלכם.
          </p>

          <h2>5. תשלום ועמלות</h2>
          <p>
            <strong>תמחור:</strong> כל המחירים מוצגים באתר ויכולים להשתנות. המחירים כוללים מע"מ במידת הצורך.<br />
            <strong>תשלום:</strong> התשלומים עבור שירותים צריכים להתבצע באמצעות שיטות התשלום המוצעות באתר.<br />
            <strong>החזרים:</strong> החזרים עשויים להינתן לפי שיקול דעתה של Remember Wish. בקשות להחזרים חייבות להיעשות תוך 30 ימים ממועד הרכישה.
          </p>

          <h2>6. קניין רוחני</h2>
          <p>
            <strong>בעלות:</strong> כל הזכויות הקנייניות בשירות, כולל עיצוב האתר, גרפיקה ותוכנה, שייכות ל-Remember Wish או לרשיון שלה.<br />
            <strong>רישיון:</strong> אתם מקבלים רישיון מוגבל, לא בלעדי ולא ניתן להעברה לגשת ולשימוש בשירות למטרות אישיות ולא מסחריות.
          </p>

          <h2>7. הגבלת אחריות</h2>
          <p>
            <strong>אין אחריות:</strong> השירות ניתן "כפי שהוא" ללא כל אחריות, מפורשת או משתמעת. Remember Wish אינה מתחייבת שהשירות יהיה בלתי מופרע או חופשי משגיאות.<br />
            <strong>הגבלה:</strong> במידה המרבית המותרת על פי חוק, Remember Wish לא תהיה אחראית לכל נזק עקיף, מקרי, מיוחד או תוצאתי שנובע משימוש בשירות.
          </p>

          <h2>8. שיפוי</h2>
          <p>
            אתם מסכימים לשפות, להגן ולשמור על Remember Wish, חברות הבת שלה, קצינים, דירקטורים, עובדים וסוכנים מפני כל תביעות, חבויות, נזקים, הפסדים והוצאות שנובעים משימושכם בשירות או מהפרת תנאים והגבלות אלה.
          </p>

          <h2>9. חוק החל</h2>
          <p>
            תנאים והגבלות אלה יוחלו ויפורשו בהתאם לחוקי מדינת ישראל. כל מחלוקות הנובעות או בקשר לתנאים אלו יהיו בסמכות הבלעדית של בתי המשפט בתל אביב, ישראל.
          </p>

          <h2>10. שינויים בתנאים</h2>
          <p>
            Remember Wish שומרת לעצמה את הזכות לשנות תנאים והגבלות אלה בכל עת. השינויים יכנסו לתוקף מייד עם פרסומם באתר. השימוש המתמשך שלכם בשירות לאחר כל שינוי מהווה הסכמה לתנאים החדשים.
          </p>

          <h2>11. פרטי יצירת קשר</h2>
          <p>
            אם יש לכם שאלות או חששות בנוגע לתנאים והגבלות אלה, אנא צרו קשר איתנו בכתובת:<br />
            <strong>אימייל:</strong> support@remember-wish.com<br />
            <strong>כתובת:</strong> [כתובת החברה שלכם]<br />
            <strong>טלפון:</strong> [מספר הטלפון שלכם]
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
