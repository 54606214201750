import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import styles from './FAQ.module.css';
import useBodyClass from './useBodyClass';

const faqData = [
  {
    question: "?למי מיועד האתר",
    answer: "האתר מיועד למשפחות הנופלים ולכל אדם שמעוניין ללמוד על מי שנפלו במלחמת חרבות ברזל בדרך אישית ומרגשת."
  },
  {
    question: "?איך אני יכול להוסיף את תמונות יקירי לאתר",
    answer: "ניתן להשתמש בטופס צור קשר באתר, להשאיר פרטים ואנחנו ניצור איתך קשר כדי להסביר איך לשלוח את המדיה. אפשרות נוספת היא ללחוץ על כפתור הווטסאפ ולשלוח אלינו את התמונות והסרטונים ישירות."
  },
  {
    question: "?האם השימוש באתר הוא בתשלום",
    answer: "לא, השירות ניתן ללא תשלום כחלק ממיזם חברתי שנועד להנציח את זכרם של הנופלים ולעזור למשפחותיהם."
  },
  {
    question: "?מה קורה עם התמונות והסרטונים שאני שולח",
    answer: "אנחנו משתמשים בחומרים אך ורק למטרת ההנצחה. אנו מתחייבים לשמור על פרטיות הנתונים ולא להעבירם לשום צד שלישי."
  }
];

const FAQ = () => {
  useBodyClass(styles.faqPage);
  const [activeIndex, setActiveIndex] = useState(null);

  const togglePanel = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div  className={styles.faqContainer}>
      <h2>שאלות ותשובות נפוצות</h2>
      <p style={{direction:'rtl'}}>
      כאן תמצאו את השאלות הנפוצות ביותר. אם עדיין יש לכם שאלה, אנא אל תהססו לשלוח לנו דוא"ל לכתובת support@Tamid.com או לשוחח איתנו דרך WhatsApp.</p>
      <div className={styles.accordion}>
        {faqData.map((item, index) => (
          <div key={index} className={styles.panel}>
            <div
            
              className={`${styles.panelHeader} ${activeIndex === index ? styles.active : ''}`}
              onClick={() => togglePanel(index)}
            >
              {item.question}
              <IoIosArrowDown className={`${styles.arrow} ${activeIndex === index ? styles.arrowActive : ''}`} />
            </div>
            <div
            style={{direction:'rtl'}}
              className={`${styles.panelContent} ${activeIndex === index ? styles.open : ''}`}
            >
              <p>{item.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
// #e3f2ff