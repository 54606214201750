import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Scanner from './components/Scanner';
import Home from './components/Home';
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
import TermsAndConditions from './components/TermsAndConditions';
import PolicyPage from './components/PolicyPage';
import MemorialForm from './components/MemorialForm';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/scanner" element={<Scanner />} />
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path='/terms' element={<TermsAndConditions />} />
        <Route path='/policy' element={<PolicyPage />} />
        <Route path='/form' element={<MemorialForm />} />
      </Routes>
    </Router>
  );
}

export default App;
