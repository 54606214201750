import React, { useEffect, useRef } from 'react';
import { MindARThree } from 'mind-ar/dist/mindar-image-three.prod.js';
import * as THREE from 'three';

const MindARThreeViewer = ({ imageTarget, assets, videoRef }) => {
  const containerRef = useRef(null);
  const mindarThreeRef = useRef(null);
  const planeRef = useRef(null);
  let renderer;

  useEffect(() => {
    console.log("Mounting MindARThreeViewer with target:", imageTarget);

    const cleanUp = () => {
      console.log("Cleaning up MindARThree instance");
      if (mindarThreeRef.current) {
        mindarThreeRef.current.stop();
        if (renderer) renderer.setAnimationLoop(null);
        if (planeRef.current) {
          planeRef.current.geometry.dispose();
          planeRef.current.material.dispose();
          planeRef.current = null;
        }
        if (videoRef.current) {
          videoRef.current.pause();
          videoRef.current.src = "";
          videoRef.current.load();
        }
      }
    };

    if (mindarThreeRef.current) cleanUp();

    mindarThreeRef.current = new MindARThree({
      container: containerRef.current,
      imageTargetSrc: imageTarget,
    });

    const { renderer: mindarRenderer, scene, camera } = mindarThreeRef.current;
    renderer = mindarRenderer;

    assets.forEach((asset, index) => {
      const anchor = mindarThreeRef.current.addAnchor(asset.targetIndex);

      const video = document.createElement('video');
      video.src = asset.video;
      video.loop = true;
      video.crossOrigin = "anonymous";
      videoRef.current = video;

      const texture = new THREE.VideoTexture(video);

      const img = new Image();
      img.src = asset.imageSrc;
      img.onload = () => {
        const aspectRatio = img.naturalWidth / img.naturalHeight;
        const videoWidth = 1;
        const videoHeight = videoWidth / aspectRatio;

        const geometry = new THREE.PlaneGeometry(videoWidth, videoHeight);
        const material = new THREE.MeshBasicMaterial({ map: texture });
        const plane = new THREE.Mesh(geometry, material);

        planeRef.current = plane;
        anchor.group.add(plane);

        anchor.onTargetFound = () => {
          console.log("Target found for:", asset.personName);
          video.play();
        };

        anchor.onTargetLost = () => {
          console.log("Target lost for:", asset.personName);
          video.pause();
        };
      };
    });

    mindarThreeRef.current.start()
      .then(() => {
        console.log("MindARThree instance started successfully with target:", imageTarget);
        renderer.setAnimationLoop(() => {
          renderer.render(scene, camera);
        });
      })
      .catch((error) => console.error("Error starting MindARThree instance:", error));

    return cleanUp;
  }, [imageTarget, assets, videoRef]);

  return (
    <div style={{ width: "100%", height: "100%" }} ref={containerRef}></div>
  );
};

export default MindARThreeViewer;
