import React, { useState } from 'react';
import styles from './HowItWorks.module.css';
import iphoneImage1 from './img/howit.png'; // replace with your image paths
import iphoneImage2 from './img/howit.png';
import iphoneImage3 from './img/howit.png';
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";

const HowItWorks = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slides = [
    {
      image: iphoneImage1,
      title: 'ההתחלה',
      text: 'לחצו על כפתור הסריקה והכוונו את המצלמה שלכם לתמונה המבוקשת לצפייה בסרטון מרגש שמחייה את הזכרונות.'
    },
    {
      image: iphoneImage2,
      title: 'כוח הזיכרון',
      text: 'באמצעות טכנולוגיה מתקדמת, החיו את זכרון יקיריכם בלחיצה אחת.'
    },
    {
      image: iphoneImage3,
      title: 'הנצחה אישית',
      text: 'צפו בסרטון הוידאו המרגש שמחייה את הזכרונות היפים ומספר את סיפור חייו'
    }
  ];
  
  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? slides.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className={styles.howItWorksContainer}>
      <div className={styles.section}>
        <div className={styles.imageContainer}>
          <div className={styles.iphoneScreenContainer}>
            <div className={styles.iphoneScreen}>
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={`${styles.imageWrapper} ${index === currentIndex ? styles.active : ''}`}
                  style={{ zIndex: index === currentIndex ? 2 : 1 }}
                >
                  <img
                    className={index === currentIndex ? styles.activeImage : styles.backgroundImage}
                    src={slide.image}
                    alt={`iPhone Screen ${index + 1}`}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div style={{direction:'rtl'}} className={styles.textContainer}>
          <h2>{slides[currentIndex].title}</h2>
          <p>{slides[currentIndex].text}</p>
          <div style={{direction:'ltr'}} className={styles.arrows}>
            <button id="prev" className={styles.arrow} onClick={handlePrevClick}>
              <FaArrowLeft />
            </button>
            <button id="next" className={styles.arrow} onClick={handleNextClick}>
              <FaArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
