import React, { useState } from "react";
import styles from "./ContactUs.module.css";
import NavBar from "./Navbar";
import Footer from "./Footer";

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with data:", formData);
  };

  return (
    <>
      <NavBar />
      <div style={{direction:'rtl'}} className={styles.container}>
        <div className={styles.leftColumn}>
          <h3 className={styles.title}>צור קשר</h3>
          <h1 className={styles.header}>יש לכם שאלה?</h1>
          <p className={styles.description}>
          אנחנו כאן לעזור! פשוט מלאו את טופס יצירת הקשר למטה ונחזור אליכם בהקדם האפשרי.</p>
        </div>
        <div className={styles.rightColumn}>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.formRow}>
              <div className={`${styles.formGroup}`}>
                <input
                  className={styles.input}
                  id="name"
                  name="name"
                  placeholder="Surname"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={`${styles.formGroup}`}>
                <input
                  className={styles.input}
                  id="email"
                  name="email"
                  placeholder="e-mail"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <textarea
              className={styles.textarea}
              id="message"
              name="message"
              placeholder="News"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <div className={styles.inputSubmit}>
              <button className={styles.submitBtn} id="submit">שלח</button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
