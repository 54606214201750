import React, { useState } from "react";
import styles from "./MemorialForm.module.css";
import NavBar from "./Navbar";
import Footer from "./Footer";
import axios from 'axios';
import Input from './Input'; // Import your custom Input component

export default function MemorialForm() {
  const [formData, setFormData] = useState({
    fullName: "",
    birthYear: "",
    deathYear: "",
    residence: "",
    role: "",
    hobbies: "",
    lifeApproach: "",
    memories: "",
    socialActivities: "",
    significantEvents: "",
    additionalInfo: "",
    deathCircumstances: "",
    familyContact: "",
    phoneNumber: "",
    email: "",
    photo: null,
    mediaFiles: [],
    voiceRecordings: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:3001/send-email', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status === 200) {
        alert('Form submitted successfully!');
      } else {
        alert('Error submitting form');
      }
    } catch (error) {
      alert('Error submitting form: ' + error.message);
    }
  };
  

  return (
    <>
      <NavBar />
      <div style={{ direction: 'rtl' }} className={styles.container}>
        <h2 className={styles.introText}>
          ראשית, אנו רוצים להביע את תנחומינו הכנים על אובדנכם ולומר שאנחנו כאן כדי לעזור לכם להנציח את זכר יקירכם בצורה מכבדת ומרגשת.
        </h2>
        <p className={styles.instructions}>
          בטופס זה תתבקשו למלא פרטים אישיים על הנופל ולהעלות תמונות או סרטונים שחשובים לכם...
        </p>
        <form className={styles.form} onSubmit={handleSubmit}>
          {/* Full Name of the Deceased */}
          <div className={styles.formGroup}>
            <label htmlFor="fullName">שם מלא של הנופל:</label>
            <Input
              id="fullName"
              name="fullName"
              type="text"
              value={formData.fullName}
              onChange={handleChange}
              divClassName={styles.inputDiv}
              inputClassName={styles.input}
              placeholder="הכנס שם מלא"
              required
            />
          </div>
          {/* Birth Year */}
          <div className={styles.formGroup}>
            <label htmlFor="birthYear">שנת לידה:</label>
            <Input
              id="birthYear"
              name="birthYear"
              type="date"
              value={formData.birthYear}
              onChange={handleChange}
              divClassName={styles.inputDiv}
              inputClassName={styles.input}
              required
            />
          </div>
          {/* Death Year */}
          <div className={styles.formGroup}>
            <label htmlFor="deathYear">שנת נפילתו:</label>
            <Input
              id="deathYear"
              name="deathYear"
              type="date"
              value={formData.deathYear}
              onChange={handleChange}
              divClassName={styles.inputDiv}
              inputClassName={styles.input}
              required
            />
          </div>
          {/* Residence */}
          <div className={styles.formGroup}>
            <label htmlFor="residence">מקום מגורים:</label>
            <Input
              id="residence"
              name="residence"
              type="text"
              value={formData.residence}
              onChange={handleChange}
              divClassName={styles.inputDiv}
              inputClassName={styles.input}
              placeholder="הכנס מקום מגורים"
              required
            />
          </div>
          {/* Role */}
          <div className={styles.formGroup}>
            <label htmlFor="role">תפקיד בצבא/עבודה:</label>
            <Input
              id="role"
              name="role"
              type="text"
              value={formData.role}
              onChange={handleChange}
              divClassName={styles.inputDiv}
              inputClassName={styles.input}
              placeholder="הכנס תפקיד"
              required
            />
          </div>
          {/* Hobbies */}
          <div className={styles.formGroup}>
            <label htmlFor="hobbies">תחביבים של הנופל:</label>
            <textarea
              className={styles.textarea}
              id="hobbies"
              name="hobbies"
              value={formData.hobbies}
              onChange={handleChange}
              required
            />
          </div>
          {/* Image Upload */}
          <div className={styles.formGroup}>
            <label htmlFor="photo">התמונה אותה תרצו לסרוק:</label>
            <Input
              id="photo"
              name="photo"
              type="file"
              onChange={handleFileChange}
              divClassName={styles.inputDiv}
              inputClassName={styles.input}
              required
            />
          </div>
          {/* Media Files Upload */}
          <div className={styles.formGroup}>
            <label htmlFor="mediaFiles">תמונות וסרטונים מהן נערוך סרטון:</label>
            <Input
              id="mediaFiles"
              name="mediaFiles"
              type="file"
              multiple
              onChange={handleFileChange}
              divClassName={styles.inputDiv}
              inputClassName={styles.input}
              required
            />
          </div>
          {/* Submit Button */}
          <div className={styles.inputSubmit}>
            <button className={styles.submitBtn} id="submit">שלח</button>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}
