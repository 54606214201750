import React, { useState, useEffect, useRef } from 'react';
import NavBar from './Navbar';
import MindARThreeViewer from '../mindar-three-viewer';
import Footer from './Footer';


function Scanner() {
  const [started, setStarted] = useState(null);
  const [assets, setAssets] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    fetch('/assets/assets.json')
      .then(response => response.json())
      .then(data => setAssets(data))
      .catch(error => console.error("Error fetching the assets JSON:", error));
  }, []);

  const handleToggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handleTogglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <>
    <div className="App">
      <NavBar />
      <div id="overlay" className={started ? 'hidden' : ''}>
        <div className="instructions">
        <h2 style={{direction:'rtl'}}>התחילו את המסע לזיכרון חי</h2>
<p style={{direction:'rtl'}}>אנא עקבו אחרי ההוראות הבאות:</p>
<ul>
  <li>אשרו את בקשת הגישה למצלמה כאשר תתבקשו לכך</li>
  <li>כוונו את המצלמה אל התמונה שברצונכם לסרוק</li>
  <li>צפו כיצד התמונה מתעוררת לחיים ומספרת את סיפורו של הנופל</li>
</ul>
          <button id="startButton" onClick={() => setStarted('three')}>התחילו</button>
        </div>
      </div>

      <div className="controls" style={{ position: 'absolute', zIndex: 10, marginTop:'100px' }}>
        <button className="button" onClick={handleToggleMute}>
          {isMuted ? '🔈' : '🔇'}
        </button>
        <button className="button" onClick={handleTogglePlay}>
          {isPlaying ? '⏸️' : '▶️'}
        </button>
      </div>

      {started === 'three' && assets.length > 0 && (
        <div className="container">
          <MindARThreeViewer 
            imageTarget={assets[0].image} 
            assets={assets}
            videoRef={videoRef}
          />
        </div>
      )}
    </div>
    <Footer />
    </>
  );
}

export default Scanner;
