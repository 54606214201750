import React from 'react';
import Footer from './Footer';
import styles from './PolicyPage.module.css';
import NavBar from './Navbar';

const PolicyPage = () => {
  return (
    <>
      <NavBar />
      <div style={{direction:'rtl'}} className={styles.policyContainer}>
        <div className={styles.section}>
          <h1>מדיניות פרטיות</h1>
          <p>
            ברוכים הבאים ל-Remember Wish. אנחנו מעריכים את הפרטיות שלכם ומחויבים להגן על המידע האישי שלכם. מדיניות פרטיות זו מסבירה כיצד אנו אוספים, משתמשים וחושפים מידע עליכם כאשר אתם משתמשים באתר ובשירותים שלנו.
          </p>
          
          <h2>1. המידע שאנו אוספים</h2>
          <p>
            אנו אוספים מידע אישי שאתם מספקים לנו ישירות, כגון כאשר אתם יוצרים חשבון, רוכשים שירותים או פונים אלינו לתמיכה. זה עשוי לכלול את שמכם, כתובת הדוא"ל, מספר הטלפון ומידע התשלום שלכם.
          </p>

          <h2>2. כיצד אנו משתמשים במידע שלכם</h2>
          <p>
            אנו משתמשים במידע שאנו אוספים כדי לספק ולשפר את השירותים שלנו, לעבד עסקאות, לתקשר אתכם ולענות על פניותיכם. אנו עשויים גם להשתמש במידע שלכם כדי לשלוח לכם חומרים פרסומיים אם בחרתם לקבלם.
          </p>

          <h2>3. שיתוף המידע שלכם</h2>
          <p>
            אנו לא משתפים את המידע האישי שלכם עם צדדים שלישיים אלא אם כן יש צורך לספק את השירותים שלנו או כנדרש על פי חוק. אנו עשויים לשתף מידע עם ספקי שירותים המסייעים לנו בהפעלת האתר ובעיבוד עסקאות.
          </p>

          <h2>4. אבטחת נתונים</h2>
          <p>
            אנו מיישמים אמצעי אבטחה סבירים כדי להגן על המידע האישי שלכם מפני גישה, שימוש או גילוי בלתי מורשים. עם זאת, אף שיטה של העברה דרך האינטרנט או אחסון אלקטרוני אינה בטוחה לחלוטין.
          </p>

          <h2>5. הזכויות שלכם</h2>
          <p>
            יש לכם את הזכות לגשת, לתקן או למחוק את המידע האישי שלכם. אם יש לכם חששות לגבי המידע שאנו מחזיקים עליכם, אנא צרו איתנו קשר באמצעות פרטי הקשר המופיעים להלן.
          </p>

          <h2>6. שינויים במדיניות זו</h2>
          <p>
            אנו עשויים לעדכן מדיניות פרטיות זו מעת לעת. נודיע לכם על כל שינוי על ידי פרסום המדיניות החדשה באתר שלנו. המשך השימוש שלכם באתר לאחר שינויים מהווה את הסכמתכם למדיניות המעודכנת.
          </p>
          
          <h2>7. צרו איתנו קשר</h2>
          <p>
            אם יש לכם שאלות לגבי מדיניות פרטיות זו, אנא צרו קשר איתנו בכתובת:<br />
            <strong>אימייל:</strong> support@remember-wish.com<br />
            <strong>כתובת:</strong> [כתובת החברה שלכם]<br />
            <strong>טלפון:</strong> [מספר הטלפון שלכם]
          </p>
        </div>

        <div className={styles.section}>
          <h1>מדיניות עוגיות</h1>
          <p>
            מדיניות עוגיות זו מסבירה כיצד Remember Wish משתמשת בעוגיות וטכנולוגיות דומות כדי לזהות אתכם כאשר אתם מבקרים באתר שלנו.
          </p>

          <h2>1. מהן עוגיות?</h2>
          <p>
            עוגיות הן קבצי נתונים קטנים שמונחים על המכשיר שלכם כאשר אתם מבקרים באתר. הן עוזרות לאתר לזכור את ההעדפות שלכם ולשפר את חוויית המשתמש שלכם.
          </p>

          <h2>2. כיצד אנו משתמשים בעוגיות</h2>
          <p>
            אנו משתמשים בעוגיות כדי לשפר את החוויה שלכם באתר שלנו, כגון זכירת פרטי ההתחברות וההעדפות שלכם. עוגיות גם עוזרות לנו לנתח כיצד האתר שלנו משומש ולשפר את תפקודו.
          </p>

          <h2>3. ניהול עוגיות</h2>
          <p>
            אתם יכולים לשלוט ולנהל עוגיות דרך הגדרות הדפדפן שלכם. עם זאת, שימו לב כי השבתת עוגיות עשויה להשפיע על תפקוד האתר שלנו.
          </p>

          <h2>4. שינויים במדיניות זו</h2>
          <p>
            אנו עשויים לעדכן מדיניות עוגיות זו מעת לעת. כל שינוי יפורסם בעמוד זה, והמשך השימוש שלכם באתר מהווה את הסכמתכם למדיניות המעודכנת.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PolicyPage;
