import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import styles from './NavBar.module.css';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarContainer}>
        <a href="/" className={styles.navbarLogo}>Tamid ✨</a>
        <div className={styles.menuIcon} onClick={toggleMenu}>
          <FaBars />
        </div>
        <ul className={isOpen ? `${styles.navMenu} ${styles.active}` : styles.navMenu}>
          <li className={styles.closeIcon} onClick={toggleMenu}>
            {isOpen? <FaTimes /> : null}
          </li>
          <li className={styles.navItem}>
            <a href="/" className={styles.navLinks}>דף הבית</a>
          </li>
          <li className={styles.navItem}>
            <a href="/scanner" className={styles.navLinks}>Scanner</a>
          </li>
          <li className={styles.navItem}>
            <a href="/about-us" className={styles.navLinks}>עלינו</a>
          </li>
          <li className={styles.navItem}>
            <a href="/contact" className={styles.navLinks}>צרו קשר</a>
          </li>
          <li className={styles.navItem}>
            <a href="/form" className={styles.navLinks}>טופס הנצחה</a>
          </li>
          {/* <li className={styles.navItem}>
            <a href="/login" className={styles.navLinks}>Log in</a>
          </li> */}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
