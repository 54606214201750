import React from 'react';
import NavBar from './Navbar';
import HowItWorks from './HowItWorks';
import styles from './Home.module.css';
import FAQ from './FAQ';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';

function Home() {
  const navigate = useNavigate();

  const handleScanClick = () => {
    navigate('/scanner');
  };

  return (
    <>
      <NavBar />
      <div className={styles.homeContainer}>
        <div className={styles.overlay}>
          <h1>זכרונות שנשארים לנצח</h1>
          <p>כוכב הנשמה שלך</p>
          <button onClick={handleScanClick}>לחצו לסריקה</button>
        </div>
      </div>

      <div className={styles.section}>
        <p>כדי שיקיריכם ייזכרו תמיד</p>
        <h2>זיכרון חי</h2>
        <p>
          המיזם שלנו נועד להנציח את זכרם של אלו שנפלו במלחמת חרבות ברזל באמצעות טכנולוגיה חדשנית. אנחנו מאפשרים למשפחות להפוך תמונות לזכרונות חיים - סרטוני וידאו מרגשים שמספרים את סיפורם האישי של הנופלים. המיזם החברתי שלנו נועד להעניק כבוד לזכרם ולשמר את זכרם של הנופלים בצורה אישית ומשמעותית
        </p>
        <button onClick={handleScanClick}>לחצו לסריקה</button>
      </div>

      <HowItWorks />
      <div className={styles.section}>
        <FAQ />
      </div>
      <Footer />
      <a
href="https://api.whatsapp.com/send?phone=972508420870&text=%D7%A9%D7%9C%D7%95%D7%9D%20%D7%94%D7%99%D7%99%D7%AA%D7%99%20%D7%A9%D7%9E%D7%97%20%D7%9C%D7%99%D7%A6%D7%95%D7%A8%20%D7%A7%D7%A9%D7%A8%20%D7%9C%D7%92%D7%91%D7%99%20%D7%94%D7%A4%D7%A8%D7%95%D7%99%D7%A7%D7%98"
className={styles.float}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp className={styles.myFloat} />
      </a>
      <div className={styles.pulse}></div>
    </>
  );
}

export default Home;
