import React from 'react';
import styles from './Input.module.css';

const Input = ({ type, name, placeholder, value, onChange, divClassName, inputClassName, ...rest }) => {
  return (
    <div className={`${styles.inputBox} ${divClassName}`}>
      <input 
        type={type} 
        name={name} 
        placeholder={placeholder} 
        value={value} 
        onChange={onChange}
        className={`${styles.inputField} ${inputClassName}`} 
        {...rest} 
      />
    </div>
  );
};

export default Input;
