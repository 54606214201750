import React from 'react';
import styles from './Footer.module.css';
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin, FaTiktok } from 'react-icons/fa';
import logo from './img/log.png';
const Footer = () => {
  return (
    <footer style={{direction:'rtl'}} className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.col} id={styles.company}>
            <img src={logo} alt="logo" className={styles.logo} />
            <p>
            אנו מחויבים לשמר את הזכרונות והמורשת של יקירינו שאיבדנו.
            </p>
            <div style={{display:'flex', gap:'10px',fontSize:'20px'}} className={styles.social}>
            <a href="#"><FaFacebook /></a>
          <a href="#"><FaTwitter /></a>
          <a href="#"><FaInstagram /></a>
          <a href="#"><FaLinkedin /></a>
          <a href="#"><FaTiktok /></a>
            </div>
          </div>
{/* 
          <div className={styles.col} id={styles.services}>
            <h3>Services</h3>
            <div className={styles.links}>
              <a href="#">Illustration</a>
              <a href="#">Creatives</a>
              <a href="#">Poster Design</a>
              <a href="#">Card Design</a>
            </div>
          </div> */}

          <div className={styles.col} id={styles.usefulLinks}>
            <h3>קישורים</h3>
            <div className={styles.links}>
              <a href="/about-us">עלינו</a>
              <a href="/service">שרותים</a>
              <a href="/policy">המדיניות שלנו</a>
              <a href="/terms">תנאים והגבלות</a>
            </div>
          </div>

          {/* <div className={styles.col} id={styles.contact}>
            <h3>Contact</h3>
            <div className={styles.contactDetails}>
              <i className="fa fa-location"></i>
              <p>FF-42, Procube Avenue, <br /> NY, USA</p>
            </div>
            <div className={styles.contactDetails}>
              <i className="fa fa-phone"></i>
              <p>+1-8755856858</p>
            </div>
          </div> */}
        </div>

        <div className={styles.row}>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
