import React from 'react';
import styles from './AboutUs.module.css';
import image1 from './img/img_1.avif'; // replace with your image paths
import image2 from './img/img_2.avif';
import image3 from './img/img_3.avif';
import NavBar from './Navbar';
import Footer from './Footer';

const AboutUs = () => {
  return (
    <>
      <NavBar />
      <div className={styles.aboutUs}>
        <div className={styles.section}>
          <div className={styles.text}>
            <h2>המטרה שלנו</h2>
            <p style={{direction:'rtl'}}>
            היא לשמר את זכרם של הנופלים  וליצור חיבור עמוק ומשמעותי בינם לבין אנשים שלא הכירו אותם וליצור זיכרון חי למשפחות. אנו מאמינים שהדרך בה אנו מציעים להנציח את הנופלים היא לא רק חדשנית, אלא גם עמוקה ומכבדת, ומאפשרת לכל אדם להרגיש כאילו תמונתו של הנופל "קמה לחיים" מול עיניו.            </p>
          </div>
          <div className={styles.image}>
            <img src={image1} alt="Honoring Memories" />
          </div>
        </div>

        <div className={`${styles.section} ${styles.reverse}`}>
          <div style={{direction:'rtl'}} className={styles.text}>
            <h2>התהליך שלנו</h2>
            <p>
            מתחיל בקשר אישי עם המשפחות. אנו מקיימים שיחה מקדימה שמטרתה להכיר את הנופלים לעומק ולהבין את הסיפורים האישיים שעומדים מאחורי התמונות והסרטונים. לאחר מכן, עורך הוידאו המקצועי שלנו משתמש בחומרים שהמשפחות שולחות לנו כדי ליצור סרטונים מרגשים ומכובדים, אשר מעבירים את רוחם של הנופלים בצורה הטובה ביותר.
            </p>
          </div>
          <div className={styles.image}>
            <img src={image2} alt="Connecting Generations" />
          </div>
        </div>

        <div className={styles.section}>
          <div style={{direction:'rtl'}} className={styles.text}>
            <h2>התרומה שלנו</h2>
            <p>
            כפרויקט חברתי שמטרתו לסייע למשפחות להנציח את יקיריהם ולעזור לאנשים שלא הכירו את הנופלים ללמוד עליהם בדרך חדשה ונגישה. אנו מתחייבים לשמור על פרטיות הנתונים של המשפחות ולא להשתמש בתמונות ובסרטונים מעבר למטרות ההנצחה.            </p>
          </div>
          <div className={styles.image}>
            <img src={image3} alt="Preserving Legacies" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
